import { LoginContext } from '@/context/login'
import { useContext, useState } from 'react'
import Input from '@/components/ui/Input'
import Account from '@/components/ui/Account'
import Button from '@/components/ui/Button'
import { useForm } from 'react-hook-form'
import { faEnvelope, faSackDollar } from '@fortawesome/pro-duotone-svg-icons'
import { faFingerprint as fasFingerprint } from '@fortawesome/pro-solid-svg-icons'
import { faLandmark, faUser } from '@fortawesome/pro-solid-svg-icons'
import AuthService from '@/services/auth'
import AuthCode from '@/components/auth/AuthCode'
import Alert from '@/components/ui/Alert'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import ListSelector from '../ui/ListSelector'
import CountrySelector from '@/components/ui/CountrySelector'

export default function InitLogin() {
	const [, loginDispatch] = useContext(LoginContext)
	const [loading, setLoading] = useState(false)
	const [accountType, setAccountType] = useState('INDIVIDUAL')
	const [error, setError] = useState(null)
	const [currency, setCurrency] = useState('')
	const [agree, setAgree] = useState(false)
	const [country, setCountry] = useState('')
	const [countryCode, setCountryCode] = useState('')
	const [amount, setAmount] = useState(0)
	const { executeRecaptcha } = useGoogleReCaptcha()
	const { register, handleSubmit, errors } = useForm()

	const onSubmit = async (data) => {
		setError(null)
		setLoading(true)

		if (accountType !== 'CORPORATE' && agree == false) {
			setLoading(false)
			setError('Please agree the terms')
			return
		}

		if (amount === 0) {
			setLoading(false)
			setError('Please enter an amount')
			return
		}

		if (currency === '') {
			setLoading(false)
			setError('Please select a currency')
			return
		}

		if (country === '') {
			setLoading(false)
			setError('Please select a country')
			return
		}


		const recaptchaToken = await executeRecaptcha('login_page')

		const config = {
			headers: {
				Recaptcha: recaptchaToken,
			},
		}

		let residency = false
		if (amount > 10000) {
			residency = true
		}

		const body = {
			email: data.email,
			type: accountType,
			currency: currency,
			amount: amount,
			country: countryCode,
		}

		console.log(body)

		await AuthService.Signup(body, config)
			.then(() => {
				loginDispatch({
					type: 'SET_LOGIN',
					payload: {
						auth: {
							email: body.email,
							code: '',
							account_type: accountType,
						},
						currentComponent: <AuthCode></AuthCode>,
					},
				})
			})
			.catch((err) => {
				setLoading(false)
				setError(err.message)
			})
		return () => { }
	}

	const formSchema = {
		email: {
			required: 'Email required',
			pattern: {
				value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
				message: 'Invalid email',
			},
		},
	}

	function UpdateCountry(value) {
		setCountry(value.name)
		setCountryCode(value.alpha3)
	} 

	return (
		<>
			{error ? <Alert type='error' title={error}></Alert> : null}
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="flex justify-between mb-4">
					<div className="w-1/2 pr-2">
						<Account
							full
							value={accountType}
							account='INDIVIDUAL'
							icon={faUser}
							label='Individual'
							onClick={() => setAccountType('INDIVIDUAL')}
						/>
					</div>
					<div className="w-1/2 pl-2">
						<Account
							full
							onClick={() => setAccountType('CORPORATE')}
							icon={faLandmark}
							account='CORPORATE'
							value={accountType}
							label='Corporate'
						/>
					</div>
				</div>
				<div className='mb-5'>
					<Input
						icon={faEnvelope}
						label='Email'
						referrer={register(formSchema.email)}
						placeholder='john.doe@synaps.io'
						name='email'
						error={errors.email}
						message={errors.email?.message}
					/>
				</div>
				<div className='mb-5'>
					<Input
						icon={faSackDollar}
						label='Amount to be contributed (in USD equivalent)'
						placeholder='100'
						name='amount'
						value={amount}
						setValue={(value) => setAmount(value)}
					/>
				</div>
				<div className='mb-5'>
					<label
						className="block text-sm font-medium text-gray-700 mb-2">
						Currency of contribution
					</label>

					<ListSelector
						onSelect={(selection) => setCurrency(selection)}
						selected={currency}
						choices={[
							{
								label: 'ETH',
								value: 'ETH',
							},
							{
								label: 'USDT',
								value: 'USDT',
							},
							{
								label: 'USDC',
								value: 'USDC',
							},
						]}></ListSelector>
				</div>
				<div className="mb-5">
					<label
						className="block text-sm font-medium text-gray-700 mb-2">
						Country of residence
					</label>
					<CountrySelector placeholder="country name" value={country} setValue={(value) => UpdateCountry(value)}/>
				</div>
				{accountType !== 'CORPORATE' ? <>
					<div className='mb-5'>
						<label
							className="block text-sm font-medium text-gray-700 mb-2">
							Terms
						</label>
						<div className="flex">
							<input className="mr-3 mt-1"
								name="isGoing"
								type="checkbox"
								checked={agree}
								onChange={() => setAgree(!agree)} />
							<p>For eligibility in community token distribution I will provide proof of holding at least 1000AGIX as per requirements.</p>

						</div>
					</div>
				</> : null}
				<div>
					<Button
						type='submit'
						full
						loading={loading}
						label='Sign Up'
					/>
				</div>
			</form>
		</>
	)
}
