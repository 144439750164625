import { useEffect } from 'react'

function Privacy() {
    useEffect(async () => {
        window.location.href = 'https://storage.googleapis.com/synaps-media/privacy-policy-synaps-12-march.pdf'
    }, [])
  

    return (
        <div>
      </div>
    )
}

export default Privacy
